/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gamepad2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.012 5.77a4.308 4.308 0 017.45-2.616.534.534 0 00.397.172h.282a.542.542 0 00.397-.172 4.308 4.308 0 017.45 2.616.62.62 0 01.012.12v5.78c0 2.632-3.583 3.415-4.68 1.022L10.036 9.89a.727.727 0 00-.532-.23H6.496a.729.729 0 00-.532.23L4.68 12.692C3.583 15.085 0 14.302 0 11.67V5.89l.002-.047a.62.62 0 01.01-.072zm14.757.202l-.004-.05a3.077 3.077 0 00-5.326-1.93 1.772 1.772 0 01-1.298.565h-.282c-.491 0-.962-.203-1.298-.565a3.077 3.077 0 00-5.326 1.93l-.004.05v5.698c0 1.311 1.784 1.7 2.33.51l1.32-2.88a.607.607 0 01.075-.123c.372-.473.94-.748 1.54-.748h3.008c.6 0 1.168.275 1.54.748.03.038.055.08.075.124l1.32 2.878c.546 1.192 2.33.802 2.33-.51V5.973zm-9.934-.027a.556.556 0 10-1.112 0 .556.556 0 001.112 0zM4.279 4.16a1.787 1.787 0 110 3.573 1.787 1.787 0 010-3.573zm9.036 1.097h-.598a.253.253 0 01-.253-.253v-.591a.252.252 0 00-.253-.253h-.873a.253.253 0 00-.253.253v.591c0 .14-.113.253-.253.253h-.585a.253.253 0 00-.253.253v.872c0 .14.114.254.253.254h.585c.14 0 .253.113.253.253v.591c0 .14.114.253.253.253h.873c.14 0 .253-.113.253-.253v-.591c0-.14.113-.253.253-.253h.598c.14 0 .253-.114.253-.254V5.51a.253.253 0 00-.253-.253z" _fill="#000"/>'
  }
})
